<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('ysgl_qiTaShouRu:add')"
        class="itemBtn btnColor"
        @click="addbut"
        >新建</el-button
      >
      <el-button
        v-if="hasPermiss('ysgl_qiTaShouRu:update')"
        class="itemBtn btnColor"
        @click="modify"
        >修改</el-button
      >
      <el-button
        v-if="hasPermiss('ysgl_qiTaShouRu:examine')"
        class="itemBtn btnColor"
        @click="toExamine"
        >审核</el-button
      >
      <el-button
        v-if="hasPermiss('ysgl_qiTaShouRu:examine:return')"
        class="itemBtn btnColor"
        @click="CancelApproval"
      >
        返审核</el-button
      >
      <el-button
        v-if="hasPermiss('ysgl_qiTaShouRu:writeOff')"
        class="itemBtn btnColor"
        @click="WriteOff"
        >核销</el-button
      >
      <el-button
        v-if="hasPermiss('ysgl_qiTaShouRu:writeOff:return')"
        class="itemBtn btnColor"
        @click="CancelPayment"
      >
        返核销</el-button
      >
      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">收支批次号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.financeNumber[0]"
                placeholder="请输入内容"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.storageId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">审核状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.approveStatus"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in shengheList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">付款状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                filterable
                clearable
                placeholder="请选择"
                v-model="queryData.writeoffStatus"
              >
                <el-option
                  v-for="item in fukuanList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp1">
            <div class="singleinpleft">新建时间:</div>
            <div class="singleinpright timebox">
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="timebut"
                v-model="time1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="singleinp1">
            <div class="singleinpleft">审核日期:</div>
            <div class="singleinpright timebox">
              <el-date-picker
                @change="paymentTime"
                v-model="time2"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="singleinp1">
            <div class="singleinpleft">付款日期:</div>
            <div class="singleinpright timebox">
              <el-date-picker
                @change="fukuanTime"
                v-model="time3"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="item_left" v-if="hasPermiss('ysgl_qiTaShouRu:query')">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            sortable
            :label="item.name"
            :prop="item.field"
            :width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <!-- 自定义排序表头 -->
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
      <!-- 审核确认 -->
      <el-dialog
        title="审核确认"
        :visible.sync="paymentBox"
        :before-close="shengheClose"
      >
        <div class="headerbpx">是否对以下付款单进行批量审核?</div>
        <div class="newForm">
          <el-form ref="FormData" label-width="120px">
            <div class="queryItem1">
              <el-form-item label="实付总金额:">
                <el-input v-model="TotalAmount" :disabled="true"></el-input>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="总票数:">
                <el-input v-model="length" :disabled="true"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footerbox">
          <el-button class="seniorbut" @click="AuditConfirmation"
            >审核确认</el-button
          >
        </div>
      </el-dialog>
      <!-- 核销确认 -->
      <el-dialog
        title="核销确认"
        :visible.sync="WriteOffBox"
        :before-close="hexiaoClose"
      >
        <div class="newForm">
          <el-form ref="paymentArray" :model="paymentArray" label-width="120px">
            <div class="queryItem1">
              <el-form-item label="核销金额:">
                <el-input
                  v-model="paymentArray.amountCollected"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="核销备注:">
                <el-input v-model="paymentArray.writeoffComment"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="资金账号:">
                <el-select v-model="paymentArray.accountId">
                  <el-option
                    v-for="item in zijingList"
                    :key="item.accountId"
                    :label="item.accountName"
                    :value="item.accountId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="核销时间">
                <el-date-picker
                  style="width: 100%"
                  class="input"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="paymentArray.writeOffTime"
                  type="datetime"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>

            <el-form-item style="margin-top: 20px" label="上传图片">
              <el-upload
                :action="uploadUrl"
                :http-request="uploadSectionImg"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg"
                :limit="fileNum"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-form>
        </div>

        <div class="footerbox">
          <el-button class="seniorbut" @click="hexiaobut">核销确认</el-button>
        </div>
      </el-dialog>

      <!-- 新建/修改 -->
      <el-dialog
        :title="addAndEditTitle"
        :visible.sync="addAndEditShow"
        @close="addAndEditClose"
      >
        <div class="newForm">
          <el-form
            ref="arraydata"
            :model="arraydata"
            :rules="rules"
            status-icon
            label-width="120px"
          >
            <div class="formbox">
              <div class="queryItem1" v-if="pageType !== 'incomeadd'">
                <el-form-item label="收支批次号:">
                  <el-input
                    v-model="arraydata.financeInNumber"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="formbox">
              <div class="queryItem1">
                <el-form-item label="收支类型:">
                  <el-select v-model="arraydata.financeType" disabled>
                    <el-option
                      v-for="(item, index) in shouzhiList"
                      :label="item.name"
                      :value="item.financeType"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="formbox">
              <div class="queryItem1">
                <el-form-item label="费用:" prop="settlementAmount">
                  <el-input v-model="arraydata.settlementAmount"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="冲销单据:" prop="financeDocumentstatusId">
                  <el-select
                    v-model="arraydata.financeDocumentstatusId"
                    class="queryInput"
                  >
                    <el-option
                      v-for="(item, index) in chongxiaoList"
                      :label="item.documentStatusName"
                      :value="item.financeDocumentstatusId"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="冲销单号:" prop="documentNumber">
                  <el-input v-model="arraydata.documentNumber"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="收支备注:">
                  <el-input
                    v-model="arraydata.comment"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>

        <div class="footerbox">
          <el-button class="seniorbut" @click="SubmitBut">保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      time1: "",
      time2: "",
      time3: "",
      FormData: {
        aaa: "",
      },
      value: "",
      queryData: {
        recordType: 2, //类型 必填
        createStartTime: "", //新建开始时间
        createEndTime: "", //新建结束时间
        storageId: "", //仓库id
        writeoffStatus: "", //核销状态
        writeoffStartTime: "", //核销开始时间
        writeoffEndTime: "", //核销结束时间
        approveStartTime: "", //审核开始时间
        approveEndTime: "", //审核结束时间
        approveStatus: "", //审核状态
        financeNumber: [], //收支批次号
      },

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "收支批次号",
          field: "financeNumber",
          width: "160",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库",
          field: "storageName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "核销金额",
          field: "settlementAmount",
          width: "160",
          sort: 5,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "审核状态",
          field: "approveStatusShow",
          width: "160",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "核销状态",
          field: "writeoffStatusShow",
          width: "160",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "关联单号",
          field: "documentNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收支备注",
          field: "comment",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        /*  {
          name: "核销备注",
          field: "comment",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        }, */
        {
          name: "新建时间",
          field: "createTime",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "reatorUserName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "审核时间",
          field: "approveTime",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核人",
          field: "approverUserName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "核销时间",
          field: "writeoffTime",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "核销人",
          field: "writeoffApproverUserName",
          width: "120",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "qitazhichu1", //页面标识
      selectTableData: [], //选中的数据
      //===============
      pageNum_member: 1, //页码
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      cangkuList: [], //仓库下拉列表
      shengheList: [], //审核状态列表
      fukuanList: [], //付款状态列表
      qudaodailiList: [], //渠道代理列表
      zijingList: [], //资金列表
      paymentBox: false, //审核弹窗
      WriteOffBox: false, //核销弹窗
      addAndEditShow: false, //
      ids: [], //审核ID数组
      TotalAmount: "", //审核总金额
      length: "", //审核个数
      WriteOffAmount: "", //核销金额
      paymentArray: {
        accountId: "", //资金账户id
        accountStatus: "", //账户类型
        amountCollected: "", //收款金额
        ids: [], //应收计费id数组
        writeoffComment: "", //核销备注
        writeOffTime: "", //
        writeOffPic: [], //
      },
      //反核销
      CancelWrite: {
        ids: [],
        writeOffPic: [],
      },
      addAndEditTitle: "新增其他收入",
      rules: {
        documentNumber: [
          { required: true, message: "请输入单号", trigger: "blur" },
        ],
        financeDocumentstatusId: [
          { required: true, message: "请选择冲销单据", trigger: "blur" },
        ],
        settlementAmount: [
          { required: true, message: "请输入费用", trigger: "blur" },
        ],
      },
      arraydata: {
        documentNumber: "", //冲销单号 必填
        financeDocumentstatusId: "", //冲销单据id
        financeDocumentstatusName: "", //冲销单据名称
        settlementAmount: "", //结算金额
        comment: "", //备注
        financeType: 0, //收支类型(0=收入,1=支出)
        storageId: "", //仓库id
        otherFinanceId: "", //收支id
      },
      chongxiaoList: [], //冲销列表
      shouzhiList: [
        {
          financeType: 0,
          name: "收入",
        },
        {
          financeType: 1,
          name: "支出",
        },
      ],
      pageType: "incomeadd", //新增还是修改
      nid: "", //修改传过来的id
      fileListImg: [], //核销图片
      fileNum: 1,
      dialogVisible: false, //
      dialogImageUrl: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.financeDocumentstatusChooseList();
    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-10); //10天前
    this.times = [this.sarttime, this.endtime];
  },
  mounted() {
    const that = this;
    this.getList();
    this.getData();
    this.getStatusValList("finance_in_record_v1.approve_status") || []; //审核状态列表
    this.getStatusValList("finance_in_record_v1.writeoff_status") || []; //付款状态列表
    // window.onresize = () => {
    //   this.clientHeight = document.body.clientHeight;
    //   return (() => {
    //     that.fetTableHeight();
    //   })();
    // };
  },
  watch: {},
  methods: {
    // 冲销单列表
    financeDocumentstatusChooseList() {
      Api.financeDocumentstatusChooseList().then((res) => {
        //console.log(res.data.result)
        this.chongxiaoList = res.data.result;
      });
    },
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    addAndEditClose() {
      this.$refs.arraydata.resetFields();
      this.arraydata = {
        documentNumber: "", //冲销单号 必填
        financeDocumentstatusId: "", //冲销单据id
        financeDocumentstatusName: "", //冲销单据名称
        settlementAmount: "", //结算金额
        comment: "", //备注
        financeType: 0, //收支类型(0=收入,1=支出)
        storageId: "", //仓库id
        otherFinanceId: "", //收支id
      };
    },
    //核销弹窗关闭
    hexiaoClose() {
      this.WriteOffBox = false;
      this.WriteOffAmount = "";
      this.paymentArray = {
        accountId: "", //资金账户id
        accountStatus: "", //账户类型
        amountCollected: "", //收款金额
        ids: [], //应收计费id数组
        writeoffComment: "", //核销备注
        writeOffTime: "", //
        writeOffPic: [],
      };
      this.fileListImg = [];
    },
    //审核弹窗关闭
    shengheClose() {
      this.paymentBox = false;
      this.TotalAmount = "";
      this.length = "";
      this.ids = [];
    },
    //新建
    addbut() {
      this.addAndEditShow = true;
      this.addAndEditTitle = "新增其他收入";
      this.pageType = "incomeadd";
      // this.$router.push(
      //   "/PayableManagement/IncomeApplication?pageType=incomeadd"
      // );
    },
    // 修改
    modify() {
    
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        this.addAndEditShow = true;
        this.nid = selectTableData[0].otherFinanceId;
        this.otherFinanceInDetail();
        this.pageType = "incomemodify";
        this.addAndEditTitle = "编辑其他收入";
        // this.$router.push(
        //   "/PayableManagement/IncomeApplication?pageType=incomemodify&nid=" +
        //     nid
        // );
      }
    },

    //上传图片
    uploadSectionImg(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            // let fileDatas = res.data.result || '';
            let fileName = res.data.result.fileName;
            let arr = [fileName];
            this.paymentArray.writeOffPic = arr || [];
            // console.log(res);
          }
        })
        .catch((err) => {});
    },
    // 删除图片
    handleRemove(flie, fileList) {
      this.paymentArray.writeOffPic = [];
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //收入详情
    otherFinanceInDetail() {
      Api.otherFinanceInDetail({ otherFinanceId: this.nid }).then((res) => {
        this.arraydata = res.data.result || {};
        this.arraydata.otherFinanceId = this.arraydata.financeInId || "";
      });
    },
    //提交
    SubmitBut() {
      this.$refs.arraydata.validate((valid) => {
        if (valid == false) {
          return;
        } else {
          let that = this;

          if (this.pageType == "incomemodify") {
            let param = JSON.parse(JSON.stringify(this.arraydata));
            let pram = {
              documentNumber: param.documentNumber,
              financeDocumentstatusId: param.financeDocumentstatusId,
              financeDocumentstatusName: param.financeDocumentstatusName,
              settlementAmount: param.settlementAmount,
              comment: param.comment,
              financeType: param.financeType,
              otherFinanceId: param.otherFinanceId,
              storageId: param.storageId,
            };
            let sign = tools.getSign(pram);
            param.sign = sign;
            // 修改

            Api.updateOtherFinance(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                setTimeout(() => {
                  that.addAndEditShow = false;
                  this.getData();
                }, 1000);
              }
            });
          } else {
            let param = JSON.parse(JSON.stringify(this.arraydata));
            let pram = {
              documentNumber: param.documentNumber,
              financeDocumentstatusId: param.financeDocumentstatusId,
              financeDocumentstatusName: param.financeDocumentstatusName,
              settlementAmount: param.settlementAmount,
              comment: param.comment,
              financeType: param.financeType,

              storageId: param.storageId,
            };
            let sign = tools.getSign(pram);
            param.sign = sign;
            // 新增
            Api.financeInAndOutApply(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                setTimeout(() => {
                  that.addAndEditShow = false;
                  this.getData();
                }, 1000);
              }
            });
          }
        }
      });
    },
    //新建
    timebut(e) {
      //console.log(e);
      if (this.time1) {
        this.queryData.createStartTime = e[0] + " " + "00:00:00";
        this.queryData.createEndTime = e[1] + " " + "00:00:00";
      } else {
        this.queryData.createStartTime = "";
        this.queryData.createEndTime = "";
      }
    },
    //审核
    paymentTime(e) {
      //console.log(e);
      if (this.time2) {
        this.queryData.createStartTime = e[0] + " " + "00:00:00";
        this.queryData.createEndTime = e[1] + " " + "00:00:00";
      } else {
        this.queryData.createStartTime = "";
        this.queryData.createEndTime = "";
      }
    },
    //付款
    fukuanTime(e) {
      //console.log(e);
      if (this.time3) {
        this.queryData.createStartTime = e[0] + " " + "00:00:00";
        this.queryData.createEndTime = e[1] + " " + "00:00:00";
      } else {
        this.queryData.createStartTime = "";
        this.queryData.createEndTime = "";
      }
    },
    //获取下拉列表
    getList() {
      //获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
    },
    //取消申请
    CancelPayment() {
      let selectTableData = this.selectTableData;
      let CancelWrite = this.CancelWrite;
      if (selectTableData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        this.$confirm("是否对已选付款单进行返核销操作?", "提示:", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then((res) => {
            for (var i = 0; i < selectTableData.length; i++) {
              CancelWrite.ids.push(Number(selectTableData[i].otherFinanceId));
            }
            Api.otherFinanceInCancelWriteOff(CancelWrite).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                this.getData();
              } else {
                this.CancelWrite.ids = [];
              }
            });
          })
          .catch(() => {});
      }
    },
    //核销
    WriteOff() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        let money = [];
        let ids = [];
        for (var i = 0; i < selectTableData.length; i++) {
          if (selectTableData[i].approveStatus !== "tong_guo:approve_status") {
            this.$message.warning("所选项有未审核单号");
            return;
          }
          ids.push(Number(selectTableData[i].otherFinanceId));
          money.push(Number(selectTableData[i].settlementAmount));
        }
        let TotalAmount = this.sum(money);

        Api.capitalAccountList().then((res) => {
          //console.log(res.data.result);
          this.zijingList = res.data.result;
          //console.log("资金", this.zijingList);
        });
        //console.log("钱列表", money);
        //console.log("总金额", TotalAmount);
        this.paymentArray.amountCollected = TotalAmount;
        this.paymentArray.ids = ids;
        this.WriteOffBox = true;
      }
    },
    //确认核销
    hexiaobut() {
      let paymentArray = this.paymentArray;
      paymentArray.isWriteoff = 1;
      Api.otherFinanceInWriteOff(paymentArray).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.getData();
          this.WriteOffBox = false;
        }
      });
    },
    //审核
    toExamine() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        this.paymentBox = true;
        //console.log(selectTableData);
        let ids = [];
        var money = [];
        for (var i = 0; i < selectTableData.length; i++) {
          ids.push(Number(selectTableData[i].otherFinanceId));
          //console.log(selectTableData[i].settlementAmount);
          if (selectTableData[i].settlementAmount != 0) {
            money.push(Number(selectTableData[i].settlementAmount));
          }
        }

        let TotalAmount = this.sum(money);
        //console.log("钱列表", money);
        //console.log("总金额", TotalAmount);
        ////console.log(ids);
        this.ids = ids;
        this.TotalAmount = TotalAmount;
        this.length = selectTableData.length;
      }
    },
    sum(arr) {
      var s = 0;
      for (var i = arr.length - 1; i >= 0; i--) {
        s = tools.add(s, arr[i]);
      }
      return tools.mathToFixed(s);
    },
    //审核确认
    AuditConfirmation() {
      //console.log(this.ids);
      Api.otherFinanceInExamine({ examine: 0, ids: this.ids }).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.getData();
          this.paymentBox = false;
        } else {
          this.ids = [];
        }
      });
    },
    //取消审核
    CancelApproval() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        this.$confirm("是否对已选数据进行返审核?", "提示", {})
          .then(() => {
            let ids = [];
            for (var i = 0; i < selectTableData.length; i++) {
              ids.push(Number(selectTableData[i].otherFinanceId));
              ////console.log(selectTableData[i].settlementAmount);
            }
            Api.otherFinanceInExamine({ examine: 1, ids: ids }).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                this.getData();
              }
            });
          })
          .catch(() => {});
      }
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 服务类型:==========> item_category_v1.service_status
      // 费项类型:==========> item_service_v1.direction_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 审核状态
        if (status == "finance_in_record_v1.approve_status") {
          let dataArr = res.data.result || [];
          this.shengheList = dataArr;
          ////console.log('审核列表',res.data.result)
        } else if (status == "finance_in_record_v1.writeoff_status") {
          let dataArr = res.data.result || [];
          this.fukuanList = dataArr;
        }
      });
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      }
    },

    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault();
        event.returnValue = false;
      }
    },

    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      // let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      // let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      ////console.log('winFlag || isFull', winFlag, isFull)
      if (winFlag == true && isFull == true) {
        this.fullscreen = true;
        this.TableStyle = false;

        ////console.log(this.tableHeigth)
      } else {
        this.fullscreen = false;
        this.TableStyle = true;
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
      //console.log(55555);
      //console.log(this.selectTableData[0]);
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他收入列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      //console.log("formData");
      //console.log(formData);
      Api.otherFinanceInList(formData).then((res) => {
        //console.log(res.data.result.data);
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      //console.log("时间时间时间时间");
      //console.log(this.value1);
      // let orderNos = this.queryData.val1;
      // this.getStr(orderNos);
      // 别的参数暂时没有
      //console.log(this.queryData);
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        recordType: 2, //类型 必填
        createStartTime: "", //新建开始时间
        createEndTime: "", //新建结束时间
        storageId: "", //仓库id
        writeoffStatus: "", //核销状态
        writeoffStartTime: "", //核销开始时间
        writeoffEndTime: "", //核销结束时间
        approveStartTime: "", //审核开始时间
        approveEndTime: "", //审核结束时间
        approveStatus: "", //审核状态
        financeNumber: [], //收支批次号
      };
      (this.time1 = ""), (this.time2 = "");
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }

  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.newForm {
  margin-left: 80px;

  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}

.headerbpx {
  width: 100%;
  display: flex;
  justify-content: center;
}

.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.singleinp1 {
  min-width: 392px;
}

.box2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  margin: 0 auto;
  display: flex;
  margin: 10px auto;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;

  .remarksbox {
    color: #606266;
    width: 50%;
    display: flex;
    padding: 5px 1%;
    font-size: 14px;
    border: 1px solid #e0e0e0;

    .remarkstitle {
      width: 7%;
      line-height: 30px;
    }

    .remarksinp {
      width: 50%;
    }
  }

  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }

  //能输入样式
  .queryItem1 {
    min-width: 300px;
    width: 100%;
    margin: 3px 1%;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;

    align-items: center;
    font-size: 14px;

    .FontStyle {
      color: #f00;
      cursor: pointer;
    }

    .queryItem1:hover {
      border: 1px solid #f0a261;
      box-shadow: 0px 0px 5px #f0a261;
    }

    .queryLabel {
      width: 100px;
      min-width: 100px;
      color: #606266;
      text-align: right;
      margin-right: 10px;
      display: flex;
      justify-content: flex-end;
    }

    .right_inpu_post {
      position: relative;

      .niming {
        position: absolute;
        right: 0;
        top: 4px;
        z-index: 99;
      }
    }

    .right_inpu {
      width: 100%;
    }

    .right_inpu ::v-deep {
      .el-input__inner {
        min-height: 28px;
        height: 28px;
        line-height: 28px;
        border: 0;
        width: 100%;
      }
    }

    .right_inpu ::v-deep {
      .el-textarea__inner {
        min-height: 28px;
        height: 28px;
        line-height: 28px;
        border: 0;
        width: 375px;
      }
    }
  }
}
</style>
